import React, { Suspense, Fragment } from "react";
import { Layout, Spin } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
import Sidebar from "../sidebar";
import UserDuration from "../userduration";
import { UserContext } from "../usercontext";
import logo from "../../assets/images/logo.png";
import { hasUser, userHelper } from "../../lib/auth";
import api from "../../lib/api";
const { Header, Content, Footer } = Layout;

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null
    };
  }

  componentWillMount() {
    // console.log("appc props", this.props);
    if (this.props.public || this.props.embed) {
      // console.log("Public page!");
    } else {
      if (!hasUser())
        this.props.children.props.history.push("/login", {
          from: this.props.location
        });
      else {
        this.getUser();
      }
    }
  }

  getUser = async () => {
    const res = await api.getUser();
    if (res && res.data.success === true) {
      this.setState({ user: res.data.user });
    }
  };

  handleDurationChange = duration => {
    if (this.state.user.currentDuration !== duration) {
      const userState = Object.assign({}, this.state.user);
      userState.currentDuration = duration;
      this.setState({ user: userState });
    }
  };

  logOut = () => {
    userHelper.logout(this.props.children.props.history);
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Suspense
          fallback={
            <div style={{ margin: "auto", paddingTop: "35vh", width: 0 }}>
              <Spin size="large" />
            </div>
          }
        >
          {this.props.embed ? null : (
            <Header
              style={{
                position: "fixed",
                zIndex: 1,
                width: "100%",
                padding: "0 22px"
              }}
            >
              <div className="logo">
                <Link to="/">
                  <img className="logo-img" src={logo} alt="Home" />
                </Link>
              </div>
              {this.state.user ? (
                <Fragment>
                  <UserDuration
                    userId={this.state.user.id}
                    durationCallback={this.handleDurationChange}
                    logOut={this.logOut}
                  />
                </Fragment>
              ) : null}
            </Header>
          )}
          <Layout>
            {!this.props.public && this.state.user ? (
              <Sidebar {...this.props} user={this.state.user} />
            ) : null}
            <Content
              style={{
                marginTop: this.props.embed ? 0 : 64,
                padding: this.props.embed ? 0 : 24,
                background: "#fff"
              }}
            >
              <UserContext.Provider value={this.state.user}>
                {this.props.children}
              </UserContext.Provider>
            </Content>
          </Layout>
          {this.props.embed ? null : (
            <Fragment>
              <div className={"beta-warn-bar"}>
                For beta testing only. Please do not use for a public live event
              </div>
              <Footer
                style={{
                  textAlign: "center",
                  paddingTop: 22,
                  paddingBottom: 22
                }}
              >
                Copyright 2019 Eventstream Inc.
              </Footer>
            </Fragment>
          )}
        </Suspense>
      </Layout>
    );
  }
}

export default AppContainer;
