import { apiClient } from "./apiClient";

const api = {
  register: async userParams => {
    return await apiClient
      .post("/users/signup", { ...userParams })
      .then(res => res);
  },
  login: async userParams => {
    return await apiClient
      .post("/users/login", { ...userParams })
      .then(res => res);
  },
  createEvent: async eventParams => {
    return await apiClient
      .post("/events/create", { ...eventParams })
      .then(res => res);
  },
  getEvent: async eventParams => {
    return await apiClient.get(`/events/${eventParams.id}`).then(res => res);
  },
  getAllEvents: async eventType => {
    let endpointPath = "";
    switch (eventType) {
      case "all":
        endpointPath = "all";
        break;
      case "upcoming":
        endpointPath = "upcoming";
        break;
      case "past":
        endpointPath = "past";
        break;
      default:
        endpointPath = "all";
        break;
    }
    return await apiClient.get(`/events/${endpointPath}`).then(res => res);
  },
  getEmbedEventData: async slug => {
    return await apiClient.get(`/embed/${slug}/event_data`).then(res => res);
  },
  getSupportedLanguages: async () => {
    return await apiClient.get(`/events/languages`).then(res => res);
  },
  getSignedUrl: async (id, params) => {
    return await apiClient
      .put(`/events/${id}/signed_url`, params)
      .then(res => res);
  },
  updateEvent: async eventParams => {
    return await apiClient
      .put(`/events/${eventParams.id}/update`, eventParams)
      .then(res => res);
  },
  getUsers: async params => {
    return await apiClient.put(`/users/`, params).then(res => res);
  },
  getUser: async params => {
    return await apiClient.get(`/users/get_details`).then(res => res);
  },
  updateUser: async userParams => {
    return await apiClient
      .put(`/users/update_details`, userParams)
      .then(res => res);
  },
  updateUserDuration: async userParams => {
    return await apiClient
      .put(`/users/update_duration`, userParams)
      .then(res => res);
  },
  deleteUser: async () => {
    return await apiClient.delete("/users").then(res => res);
  },
  adminDeleteUsers: async userId => {
    console.log(userId, "userId");
    return await apiClient.delete(`/users/${userId}/delete`).then(res => res);
  },
  blockUser: async userId => {
    return await apiClient.put(`/users/${userId}/block`).then(res => res);
  },
  updateChromaSettings: async eventParams => {
    return await apiClient
      .put(`/events/${eventParams.id}/update_chroma_settings`, eventParams)
      .then(res => res);
  },
  validateSlug: async params => {
    return await apiClient
      .put(`/events/validate_slug`, params)
      .then(res => res);
  },
  updateEventStatus: async eventParams => {
    const endpointPath =
      eventParams.currentStatus === "started" ? "stop_stream" : "start_stream";
    return await apiClient
      .put(`/events/${eventParams.id}/${endpointPath}`, eventParams)
      .then(res => res);
  },
  verifyEmail: async verification_token => {
    return await apiClient
      .put(`/users/verify_email`, { verification_token })
      .then(res => res);
  },
  resetPasswordEmail: async email => {
    return await apiClient
      .put(`/users/reset_password_email`, { email })
      .then(res => res);
  },
  validateResetToken: async password_reset_token => {
    return await apiClient
      .put(`/users/validate_reset_token`, { password_reset_token })
      .then(res => res);
  },
  deleteEvent: async eventSlug => {
    return await apiClient.delete(`/events/${eventSlug}`).then(res => res);
  },
  updatePassword: async userParams => {
    return await apiClient
      .put(`/users/update_password`, userParams)
      .then(res => res);
  },
  resendVerificationEmail: async email => {
    return await apiClient
      .put(`/users/validate_email`, { email })
      .then(res => res);
  }
  /*resendVerificationEmail: async userParams =>
    await apiClient
      .put(`/users/resend_verification_email`, { userParams })
      .then(res => res)
  */
};

export default api;
