import { message } from "antd";
import { db } from "../../lib/firebase.js";
import moment from "moment";
import api from "../../lib/api";
import { userHelper } from "../../lib/auth";

const EmailVerifyAlert = user => {
  const userId =
    process.env.NODE_ENV === "production" ? `${user.id}` : `${user.id}_dev`;

  db.collection("users")
    .doc(userId)
    .get()
    .then(doc => {
      console.log(doc.exists, "Exists");
      if (doc.exists) {
        let userData = doc.data();
        if (!userData.verified) {
          message.error(
            "Please check your email to verify your email address.",
            0
          );
        }
      }
    });
  db.collection("users")
    .doc(userId)
    .onSnapshot(doc => {
      if (doc.exists) {
        let userData = doc.data();
        if (userData.verified) {
          message.destroy();
        }
      }
    });
};

const formatIntercomUser = user => {
  return {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: `${userHelper.getId()}`,
    name: user.name,
    email: user.email,
    phone: user.phone_number,
    signed_up_at: moment(user.created_at).valueOf() / 1000,
    created_at: moment(user.created_at).valueOf() / 1000,
    companies: [{ name: user.company, company_id: `${userHelper.getId()}` }]
  };
};

const setUpIntercom = async () => {
  const res = await api.getUser();
  if (res && res.data.success === true) {
    window.Intercom("boot", formatIntercomUser(res.data.user));
  }
};

const updateIntercom = user => {
  window.Intercom("update", formatIntercomUser(user));
};

const shutDownIntercom = () => {
  window.Intercom("shutdown");
};

export { EmailVerifyAlert, setUpIntercom, updateIntercom, shutDownIntercom };
