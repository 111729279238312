import React from "react";
import { Redirect } from "react-router-dom";
import { userHelper } from "../../lib/auth";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasUser: userHelper.hasKey()
    };
  }

  render() {
    return (
      <div>
        {this.state.hasUser ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: this.props.location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: this.props.location }
            }}
          />
        )}
      </div>
    );
  }
}

export default Home;
