import axios from "axios";
import { userHelper } from "../lib/auth";
import { message } from "antd";

const API_URL = process.env.REACT_APP_API_URL;
const API_TIMEOUT = 3000;

const errorResponseHandler = error => {
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  )
    return Promise.reject(error);

  if (error.response) message.error(error.response.data.message);
};

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT
});

apiClient.interceptors.response.use(response => response, errorResponseHandler);

const updateApiKey = (isLogOff = false) => {
  if (!isLogOff) {
    const apiKey = userHelper.getKey();
    if (apiKey) apiClient.defaults.headers.common["Authorization"] = apiKey;
    else throw new Error("Api key not found");
  } else {
    apiClient.defaults.headers.common["Authorization"] = null;
  }
};

const instance = axios.create({
  baseURL: "http://kastio-ai.s3.amazonaws.com"
});
export { apiClient, updateApiKey, instance };
