import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "../components/home";
import AppContainer from "../components/appcontainer";
import NotFound from "../components/notfound";

const Login = lazy(() => import("../components/login"));
const Register = lazy(() => import("../components/register"));
const EventsList = lazy(() => import("../components/eventslist"));
const CreateEvent = lazy(() => import("../components/createevent"));
const EditEvent = lazy(() => import("../components/editevent"));
const PreviewEvent = lazy(() => import("../components/preview"));
const ControlRoom = lazy(() => import("../components/controlroom"));
const EventEmbed = lazy(() => import("../components/embed"));
const EventAudioEmbed = lazy(() => import("../components/embed/audioEmbed"));
const EventPublicView = lazy(() => import("../components/publicview"));
const ChromaCaptions = lazy(() => import("../components/chromaCaptions"));
const AccountSettings = lazy(() => import("../components/accountsettings"));
const VerifyEmail = lazy(() => import("../components/login/verifyEmail"));
const ForgotPassword = lazy(() => import("../components/forgotPassword"));
const ResetPassword = lazy(() => import("../components/resetPassword"));
const UsersList = lazy(() => import("../components/userslist"));

const routes = [
  {
    path: "/",
    exact: true,
    public: true,
    title: "Home",
    component: Home
  },
  {
    path: "/login",
    public: true,
    embed:true,
    title: "Login",
    component: Login
  },
  {
    path: "/verify_email",
    public: true,
    title: "Verify Email",
    component: VerifyEmail
  },
  {
    path: "/forgot_password",
    public: true,
    title: "Forgot password",
    component: ForgotPassword
  },
  {
    path: "/reset_password",
    public: true,
    title: "Reset password",
    component: ResetPassword
  },
  {
    path: "/register",
    public: true,
    embed:true,
    title: "Register",
    component: Register
  },
  {
    path: "/dashboard/account_settings",
    title: "Account Settings",
    component: AccountSettings
  },
  {
    path: "/dashboard/users",
    title: "UsersList",
    component: UsersList
  },
  {
    path: "/dashboard/:eventType?",
    title: "EventList",
    component: EventsList
  },
  {
    path: "/event/new",
    title: "Create Event",
    exact: true,
    component: CreateEvent
  },
  {
    path: "/event/:id/edit",
    title: "Edit event",
    exact: true,
    component: EditEvent
  },
  {
    path: "/event/:id/preview",
    title: "Event preview",
    component: PreviewEvent
  },
  {
    path: "/event/:id/controlroom",
    title: "Event Control Room",
    component: ControlRoom
  },
  {
    path: "/event/:slug/embed",
    title: "Event Embed",
    public: true,
    embed: true,
    component: EventEmbed
  },
  {
    path: "/event/:slug/audio_embed",
    title: "Event Audio Embed",
    public: true,
    embed: true,
    component: EventAudioEmbed
  },
  {
    path: "/event/:slug/captions-burn-in",
    title: "Chroma Captions",
    public: true,
    embed: true,
    component: ChromaCaptions
  },
  //This should be last
  {
    path: "/event/:slug/:eventtitle",
    title: "Event Public View",
    public: true,
    embed: true,
    component: EventPublicView
  }
];

const CustomRoute = ({ component: Component, ...otherParams }) => {
  return (
    <Route
      {...otherParams}
      render={props => (
        <AppContainer {...otherParams}>
          <Component {...props} />
        </AppContainer>
      )}
    />
  );
};

const mainAppRoutes = () => {
  return (
    <Router>
      <div>
        <Switch>
          {routes.map((route, i) => (
            <CustomRoute key={i} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default mainAppRoutes;
