import React from "react";
import { Alert, message } from "antd";
import "./style.css";
import { db } from "../../lib/firebase.js";

class UserDuration extends React.Component {
  constructor(props) {
    super(props);
    this.userId =
      process.env.NODE_ENV === "production"
        ? `${props.userId}`
        : `${props.userId}_dev`;
    this.state = {
      currentDuration: 0,
      outOfCredits: false,
      loading: false
    };
    this.firebaseListener = null;
  }

  componentWillMount() {
    this.firebaseListener = db
      .collection("users")
      .doc(this.userId)
      .onSnapshot(doc => {
        if (doc.exists) this.handleUserChanges(doc.data());
      });
  }

  componentWillUnmount() {
    this.firebaseListener();
  }

  handleUserChanges = data => {
    if (data.blocked || data.deleted) {
      message.success("Account has been deleted");
      this.props.logOut();
    }
    this.setDuration(data);
  };

  setDuration = duration => {
    const currentDuration = duration.currentDurationInroom ? duration.currentDurationInroom.toFixed(2) :null;
    if (this.props.durationCallback)
      this.props.durationCallback(currentDuration);
    this.setState({
      currentDuration: Math.max(currentDuration, 0.0),
      outOfCredits: currentDuration < 0,
      loading: true
    });
  };

  formatDuration = duration => {
    const secs = ((duration % 1).toFixed(2) * 60).toFixed(0);
    const paddedSecs = ("0" + secs).slice(-2);
    const mins = Math.trunc(duration);
    return `${mins}:${paddedSecs}`;
  };

  renderOutOfCreditsError = () => (
    <div className="ooc-container">
      <Alert
        message="Your trial time has expired. Please fund your account to continue or contact us if you need more time"
        type="error"
      />
    </div>
  );

  renderDuration = () =>
    !this.state.loading ? null : (
      <div className="duration-container">
        <div
          className={`duration-btn ${
            this.state.currentDuration < 5 ? "duration-danger fade" : ""
          }`}
        >
          {"Trial time remaining: " +
            this.formatDuration(this.state.currentDuration) +
            " mins"}
        </div>
      </div>
    );

  render() {
    return this.props.isHidden
      ? null
      : this.state.outOfCredits
      ? this.renderOutOfCreditsError()
      : this.renderDuration();
  }
}

export default UserDuration;
