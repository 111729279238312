import jsCookie from "js-cookie";
import { updateApiKey } from "./apiClient";
import { message } from "antd";

const APP_COOKIE = "kastio-ml-";
const cookieOptions = {
  domain: process.env.REACT_APP_HOSTNAME,
  secure: process.env.NODE_ENV === "production",
  httpOnly: true
};

const cookieHelper = {
  getCookie(name, options = cookieOptions) {
    return name && name.length
      ? jsCookie.get(APP_COOKIE + name, options)
      : jsCookie.get();
  },
  setCookie(name, options = cookieOptions) {
    return name && name.length
      ? jsCookie.set(APP_COOKIE + name, options)
      : new Error("Cookie key cannot be empty");
  },
  clearCookies() {
    Object.keys(jsCookie.get()).forEach(cookieName => {
      jsCookie.remove(cookieName);
    });
  }
};

const userHelper = {
  hasKey() {
    return cookieHelper.getCookie("apiKey") != null;
  },
  getKey() {
    return cookieHelper.getCookie("apiKey");
  },
  getId() {
    return cookieHelper.getCookie("userid");
  },
  login(user) {
    cookieHelper.setCookie("userid", user.id);
    cookieHelper.setCookie("apiKey", user.token);
    window.localStorage.setItem("userStatus", "loggedin");
    updateApiKey();
  },
  logout(history) {
    if (history) {
      cookieHelper.clearCookies();
      updateApiKey(true);
      history.push("/");
      window.localStorage.setItem("userStatus", "loggedout");
      message.destroy();
    } else {
      throw new Error("History ref required");
    }
  },
  updateKey() {
    updateApiKey();
  }
};

const hasUser = () => {
  if (userHelper.hasKey()) {
    userHelper.updateKey();
    return true;
  } else return false;
};

window.addEventListener("storage", e => {
  if (e.key === "userStatus" && e.newValue === "loggedout")
    window.location.reload();
});

export { cookieHelper, userHelper, hasUser };
