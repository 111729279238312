import firebase from "firebase/app";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyDrVM1LyQjz3F7-tFaWfHL2JxOTdAWmCRg",
  authDomain: "kastio-188014.firebaseapp.com",
  databaseURL: "https://kastio-188014.firebaseio.com",
  projectId: "kastio-188014",
  storageBucket: "kastio-188014.appspot.com"
});

const db = firebase.firestore();
const auth = firebase.auth;

export { firebase, db, auth };
