import React from "react";
import { Layout, Menu, Icon } from "antd";
import "./style.css";
import { cookieHelper, userHelper } from "../../lib/auth";
import { setUpIntercom, shutDownIntercom } from "../onboardhelpers";
const { Sider } = Layout;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const sidebarPrefCookie = cookieHelper.getCookie("sidebarPref") === "true";
    this.initialSelectedItem = 0;
    this.state = {
      collapsed: sidebarPrefCookie,
      userId: userHelper.getId()
    };
  }

  componentWillMount() {
    console.log(this.props.user, "USer")
    const currentLocation = this.props.children.props.location.pathname;
    if (currentLocation.includes("/dashboard")) {
      const currentPath = this.props.children.props.match.params.eventType;
      this.menuItemMap = [
        "",
        "all",
        "upcoming",
        "past",
        "account_settings",
        "users"
      ];
      const selectedItem = this.menuItemMap.indexOf(currentPath);
      this.initialSelectedItem = selectedItem === -1 ? 1 : selectedItem;
    }
    if (this.state.userId) setUpIntercom();
  }

  componentWillUnmount() {
    shutDownIntercom();
  }

  onCollapse = collapsed => {
    cookieHelper.setCookie("sidebarPref", collapsed);
    this.setState({ collapsed });
  };

  handleMenuClick = ({ key }) => {
    switch (key) {
      case "1":
        this.props.children.props.history.push("/dashboard/all");
        break;
      case "2":
        this.props.children.props.history.push("/dashboard/upcoming");
        break;
      case "3":
        this.props.children.props.history.push("/dashboard/past");
        break;
      case "4":
        this.props.children.props.history.push("/dashboard/account_settings");
        break;
      case "5":
        this.props.children.props.history.push("/dashboard/users");
        break;
      case "6":
        window.open("http://support.kastio.com");
        break;
      case "7":
        userHelper.logout(this.props.children.props.history);
        break;
      default:
        console.log("Clicked ", key);
    }
  };

  render() {
    return (
      <Sider
        style={{ paddingTop: 64 }}
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          defaultSelectedKeys={[this.initialSelectedItem.toString()]}
          mode="inline"
          onClick={this.handleMenuClick}
        >
          <Menu.Item key="1">
            <Icon type="align-left" />
            <span>All Events</span>
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="vertical-left" />
            <span>Upcoming Events</span>
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="vertical-right" />
            <span>Past Events</span>
          </Menu.Item>
          <Menu.Item key="4">
            <Icon type="profile" />
            <span>Account</span>
          </Menu.Item>
          {this.props.user.admin ? (
            <Menu.Item key="5">
              <Icon type="profile" />
              <span>User dashboard</span>
            </Menu.Item>
          ) : null}
          <Menu.Item key="6">
            <Icon type="question-circle" />
            <span>Help Center</span>
          </Menu.Item>
          <Menu.Item key="7">
            <Icon type="logout" />
            <span>Sign out</span>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

export default Sidebar;
