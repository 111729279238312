// https://github.com/ant-design/ant-design/issues/12011#issuecomment-484495318 <- Issue fix
// https://gist.github.com/sunel/6070165724b02d4d35bb8c46a63105ff <- Icons list
export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";
export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";
export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";
export { default as UserOutline } from "@ant-design/icons/lib/outline/UserOutline";
export { default as AlignLeftOutline } from "@ant-design/icons/lib/outline/AlignLeftOutline";
export { default as ExportOutline } from "@ant-design/icons/lib/outline/ExportOutline";
export { default as ImportOutline } from "@ant-design/icons/lib/outline/ImportOutline";
export { default as VerticalLeftOutline } from "@ant-design/icons/lib/outline/VerticalLeftOutline";
export { default as VerticalRightOutline } from "@ant-design/icons/lib/outline/VerticalRightOutline";
export { default as LogoutOutline } from "@ant-design/icons/lib/outline/LogoutOutline";
export { default as ProfileOutline } from "@ant-design/icons/lib/outline/ProfileOutline";
export { default as LockOutline } from "@ant-design/icons/lib/outline/LockOutline";
export { default as YoutubeOutline } from "@ant-design/icons/lib/outline/YoutubeOutline";
export { default as CalendarOutline } from "@ant-design/icons/lib/outline/CalendarOutline";
export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";
export { default as CloseCircleOutline } from "@ant-design/icons/lib/outline/CloseCircleOutline";
export { default as ClockCircleOutline } from "@ant-design/icons/lib/outline/ClockCircleOutline";
export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";
export { default as CheckOutline } from "@ant-design/icons/lib/outline/CheckOutline";
export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";
export { default as CheckCircleFill } from "@ant-design/icons/lib/fill/CheckCircleFill";
export { default as CaretUpFill } from "@ant-design/icons/lib/fill/CaretUpFill";
export { default as CaretDownFill } from "@ant-design/icons/lib/fill/CaretDownFill";
export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";
export { default as CopyOutline } from "@ant-design/icons/lib/outline/CopyOutline";
export { default as EyeOutline } from "@ant-design/icons/lib/outline/EyeOutline";
export { default as QuestionCircleOutline } from "@ant-design/icons/lib/outline/QuestionCircleOutline";
export { default as InboxOutline } from "@ant-design/icons/lib/outline/InboxOutline";
export { default as ExclamationCircleOutline } from "@ant-design/icons/lib/outline/ExclamationCircleOutline";
export { default as AudioFill } from "@ant-design/icons/lib/fill/AudioFill";